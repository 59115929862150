import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text, Image } from "../Core"
import Button from "../Buttons/Button"
import classNames from "classnames"
import { Section, Container } from "../Layouts"
import MobileCarousel from "../Carousels/MobileCarousel"
import { useInView, InView } from "react-intersection-observer"

function OurWorkPreview({ className }) {
  const ref = useRef(null)

  const data = useStaticQuery(graphql`
    query {
      ourWork: allOurWorkJson(
        sort: { order: ASC, fields: order }
        filter: { hasThisPage: { eq: true } }
      ) {
        nodes {
          title
          category
          query {
            blurb
            ourServicesSingleImages
          }
        }
      }
    }
  `)

  const ourWorkPages = data.ourWork.nodes
  const mainClasses = classNames(className)

  const clientsReviewCard = client => (
    <div className="owp__card">
      <div className={`owp__card--container`}>
        <div className="owp__card--content">
          <Image
            width="442"
            height="221"
            publicId={client.query.ourServicesSingleImages[0]}
            wrapperClassName="is-hidden-touch rounded"
          />
          <Image
            width="381"
            height="327"
            publicId={client.query.ourServicesSingleImages[1]}
            wrapperClassName="is-hidden-desktop rounded"
          />
          <Text as="h5" text={client.category} className="owp__title mb-1" />
          <Text as="p" text={client.query.blurb} className="mb-1" />
          <Button
            buttonText="Learn More"
            href={`/${client.title}`}
            destination="internal"
            contained
          />
        </div>
      </div>
    </div>
  )
  return (
    <Section className={`our-work-preview owp-sctn ${mainClasses}`}>
      <Container className="no-padding-touch">
        {/** DESKTOP VIEW */}
        <div ref={ref} style={{ scrollPadding: 100 }}>
          <InView
            as="div"
            threshold={0.8}
            onChange={(inView, entry) => {
              if (inView) {
                const y =
                  ref.current.getBoundingClientRect().top + window.scrollY + -50
                window.scrollTo({ top: y, behavior: "smooth" })
              }
            }}
            className="owp is-hidden-touch">
            <div className="owp__header">
              <Text
                as="h4"
                text="Transforming Businesses, <br>Driving Results"
              />
            </div>
            <div className="owp__card-sctn">
              {ourWorkPages.map((client, i) => (
                <div className={i === 0 ? "mt-0" : "mt-1"}>
                  {clientsReviewCard(client)}
                </div>
              ))}
            </div>
          </InView>
        </div>
        {/** MOBILE VIEW */}
        <div className="owp__slider is-hidden-desktop">
          <Text as="h4" text="Transforming Businesses, <br>Driving Results" />
          <MobileCarousel>
            {ourWorkPages.map((page, i) => (
              <div className="owp__slider-card">
                <Image
                  publicId={page.query.ourServicesSingleImages[1]}
                  className="owp__slider-card-image"
                />
                <div className="owp__slider-content">
                  <Text
                    as="h5"
                    text={page.category}
                    className="mb-1 owp__slider-card-title"
                  />
                  <Text text={page.query.blurb} />
                  <Button
                    buttonText="Learn More"
                    href={`/${page.title}`}
                    destination="internal"
                    contained
                  />
                </div>
              </div>
            ))}
          </MobileCarousel>
        </div>
      </Container>
    </Section>
  )
}

export default OurWorkPreview
