import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text, Image } from "../Core"
import Button from "../Buttons/Button"
import Quote from "../../../static/images/quote.svg"
import classNames from "classnames"

function WhatOurClientsSay({ className }) {
  const data = useStaticQuery(graphql`
    query {
      allUniquePagesJson(filter: { title: { eq: "services/" } }) {
        nodes {
          whatOurClientsSay {
            heading
            button {
              buttonText
              href
              destination
            }
            quote
            reviewerInfo
            practiceName
            desktopImage
            mobileImage
          }
        }
      }
    }
  `)

  const { whatOurClientsSay } = data.allUniquePagesJson.nodes[0]
  const mainClasses = classNames(className)

  return (
    <div className={`services__clients ${mainClasses}`}>
      <div className="rounded bg-secondary services__clients-header">
        <Text className="color-white" text={whatOurClientsSay.heading} />
        <Button
          buttonText={whatOurClientsSay.button.buttonText}
          href={whatOurClientsSay.button.href}
          appearance="white"
          className="hide-tablet"
        />
      </div>
      <div className="rounded services__clients-review">
        <div className="services__clients-review-content">
          <Quote />
          <Text
            as="p"
            text={whatOurClientsSay.quote}
            className="services__clients-review-quote"
          />
          <Text
            as="p"
            text={whatOurClientsSay.reviewerInfo}
            className="services__clients-review-info"
          />
          <Text
            as="p"
            text={whatOurClientsSay.practiceName}
            className="services__clients-review-pn"
          />
        </div>
        <div className="services__clients-review-image">
          <Image
            width="406"
            height="512"
            publicId={whatOurClientsSay.desktopImage}
            wrapperClassName="is-hidden-touch"
          />

          <Image
            width="326"
            height="332"
            publicId={whatOurClientsSay.mobileImage}
            wrapperClassName="is-hidden-desktop"
          />
        </div>
      </div>
      <Button
        buttonText={whatOurClientsSay.button.buttonText}
        href={whatOurClientsSay.button.href}
        contained
        className="hide-desktop"
      />
    </div>
  )
}

export default WhatOurClientsSay
