import { graphql } from "gatsby"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Container, Section } from "../components/Layouts"
import { Text, Image } from "../components/Core"
import WhatOurClientsSay from "../components/Services/clients"
import Contact from "../components/Services/contact"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import Button from "../components/Buttons/Button"
import OurWorkPreview from "../components/Services/ourWorkPreview"

function SingleServiceCategory(props) {
  const post = props.data.allServiceCategoriesJson.nodes[0]
  const {
    heroSection: { heading, blurb, desktopImage, mobileImage },
    serviceCards
  } = post

  return (
    <Layout
      navSpacer
      className="single-service-category ssc"
      path={props.path}
      theme="dark">
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <Section>
        <Container>
          <div className="ssc__heading">
            <Text as="h1" text={heading} />
            <div className="ssc__heading-image">
              <Image
                publicId={desktopImage}
                wrapperClassName="is-hidden-touch"
              />
              <Image
                publicId={mobileImage}
                wrapperClassName="is-hidden-desktop"
              />
            </div>
            <div className="ssc__heading-blurb">
              <Text text={blurb} />
              <div className="is-hidden-touch">
                <UpRightArrow />
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="ssc__card-wrapper">
            {serviceCards.map((serviceCard, index) => {
              const { heading, text, desktopImage, mobileImage, button } =
                serviceCard

              return (
                <div key={index} className="ssc__card">
                  <Image
                    publicId={desktopImage}
                    wrapperClassName="ssc__card-image is-hidden-touch"
                  />
                  <div className="ssc__card-content">
                    <Text as="h3" text={heading} />
                    <Image
                      publicId={mobileImage}
                      wrapperClassName="is-hidden-desktop"
                    />
                    <Text text={text} className="mt-2" />
                    <Button {...button} contained />
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </Section>

      <OurWorkPreview />

      <Section>
        <Container>
          <WhatOurClientsSay className="dark-theme" />
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <Contact className="dark-theme" arrow={false} />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query serviceCategoriesPage($title: String!) {
    allServiceCategoriesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          heading
          blurb
          desktopImage
          mobileImage
        }
        serviceCards {
          button {
            buttonText
            href
            appearance
            destination
          }
          heading
          text
          desktopImage
          mobileImage
        }
      }
    }
  }
`

export default SingleServiceCategory
